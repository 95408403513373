import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, DataTable} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import mucMobile from "../../../assets/KN-A39-small-V2.svg";
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION 
const pageMeta = {
    title: `Safety Data and Adverse Reactions for KEYNOTE-A39`,
    keywords: `keynote-a39 safety data, adverse reactions for keytruda +  enfortumab vedotin`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the adverse reactions associated with the KEYNOTE-A39 clinical trial.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/urothelial-cancer-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/urothelial-cancer-first-line-combination-therapy/","@type":"MedicalWebPage","name":"Safety Data from KEYNOTE⁠-⁠A39","description":"The safety of KEYTRUDA in combination with enfortumab vedotin was investigated in KEYNOTE⁠-⁠A39 in patients with locally advanced or metastatic urothelial cancer."}`
    ]
}

//from /src/util/data/indications.js
// UPDATE THIS TO THE CORRECT INDICATION #
const indicationId = 36;
const jobCode = jobCodes[15].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                ]
        },
    ],

    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

//alt text
const mucA39alt = "Safety Data for KEYNOTE⁠-⁠A39";
const muc869alt = "";

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Safety Data for KEYNOTE⁠-⁠A39' },
]

const additionalInformationFootnotes = [
    {
        label: "a.",
        text: "Graded per NCI CTCAE v4.03"
    },
    {
        label: "b.",
        text: "Includes multiple terms."
    },
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                            <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock
                                definitions='COVID-19 = Coronavirus Disease 2019; ILD = interstitial lung disease.'
                            >
                                <p><strong>The safety of KEYTRUDA in combination with enfortumab vedotin was investigated in KEYNOTE&#8288;-&#8288;A39 in patients with locally advanced or metastatic urothelial cancer.</strong></p>
                                <p><strong>A total of 440 patients received KEYTRUDA 200 mg on day 1 and enfortumab vedotin 1.25&nbsp;mg/kg on days 1 and 8 of each 21-day cycle compared to 433 patients who received gemcitabine on days 1 and 8 and investigator’s choice of cisplatin or carboplatin on day 1 of each 21-day cycle. Among patients who received KEYTRUDA and enfortumab vedotin, the median duration of exposure to KEYTRUDA was 8.5 months (range: 9 days to 28.5 months).</strong></p>
                                <h4>Fatal adverse reactions</h4>
                                <p>Fatal adverse reactions occurred in 3.9% of patients treated with KEYTRUDA in combination with enfortumab vedotin including acute respiratory failure (0.7%), pneumonia (0.5%), and pneumonitis/ILD (0.2%).</p>
                                <h4>Serious adverse reactions</h4>
                                <p>Serious adverse reactions occurred in 50% of patients receiving KEYTRUDA in combination with enfortumab vedotin. Serious adverse reactions in &#8805;2% of patients receiving KEYTRUDA in combination with enfortumab vedotin were rash (6%), acute kidney injury (5%), pneumonitis/ILD (4.5%), urinary tract infection (3.6%), diarrhea (3.2%), pneumonia (2.3%), pyrexia (2%), and hyperglycemia (2%).</p>
                                <h4>Permanent discontinuation</h4>
                                <p>Permanent discontinuation of KEYTRUDA occurred in 27% of patients. The most common adverse reactions (&#8805;2%) resulting in permanent discontinuation of KEYTRUDA were pneumonitis/ILD (4.8%) and rash (3.4%).</p>
                                <h4>Dose interruptions</h4>
                                <p>Dose interruptions of KEYTRUDA occurred in 61% of patients. The most common adverse reactions (≥2%) resulting in interruption of KEYTRUDA were rash (17%), peripheral neuropathy (7%), COVID-19 (5%), diarrhea (4.3%), pneumonitis/ILD (3.6%), neutropenia (3.4%), fatigue (3%), alanine aminotransferase increased (2.7%), hyperglycemia (2.5%), pneumonia (2%), and pruritis (2%).</p>
                            </TextBlock>

                                <DataTable
                                    title="Adverse Reactions 20% (All Grades) in Patients Treated With KEYTRUDA in Combination with Enfortumab Vedotin in KEYNOTE-A39"
                                    mobileImage={() => <img src={mucMobile} style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }} alt={mucA39alt} />}
                                    desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/KN-A39-large-V2.png" alt={mucA39alt} />}
                                    footnotes={additionalInformationFootnotes} 
                                    definitions='NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events.'
                                    additionalInformation={[
                                        {
                                            title: '',
                                            text: `
                                                <p>
                                                Clinically relevant adverse reactions (<20%) include pyrexia (18%), dry skin (17%), vomiting (12%), pneumonitis/ILD (10%), hypothyroidism (10%), blurred vision (6%), infusion site extravasation (2%), and myositis (0.5%).
                                                <p>
                                            `
                                        },
                                    ]}  
                                />
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}
                    
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
